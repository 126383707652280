import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46')
];

export const server_loads = [0,5];

export const dictionary = {
		"/": [~8],
		"/allagi-password": [9],
		"/allagi-password/sent": [11],
		"/allagi-password/[email]/[code]": [10],
		"/app-external": [12],
		"/audiobooks/anazitisi-vivliwn": [14,[3]],
		"/audiobooks/anazitisi-vivliwn/[type]": [15,[3]],
		"/audiobooks/syggrafeis-afigites/[id]/[...slug]": [16],
		"/audiobooks/syllogi-vivliwn": [17],
		"/audiobooks/syllogi-vivliwn/c/[id]/[...slug]": [19],
		"/audiobooks/syllogi-vivliwn/[id]/[...slug]": [18],
		"/audiobooks/ta-vivlia-mou": [20,[4]],
		"/audiobooks/ta-vivlia-mou/favorites": [21,[4]],
		"/audiobooks/ta-vivlia-mou/reviewed": [22,[4]],
		"/audiobook/[id]/[...slug]": [13,[2]],
		"/brandsgalaxyoffer20": [~23],
		"/cosmoteforyou": [24],
		"/faq": [~25],
		"/layouts/[id]": [26],
		"/o-logariasmos-mou": [27,[5]],
		"/o-logariasmos-mou/coupon": [28,[5]],
		"/o-logariasmos-mou/credits": [~29,[5]],
		"/o-logariasmos-mou/password": [30,[5]],
		"/o-logariasmos-mou/payment": [31,[5]],
		"/o-logariasmos-mou/payment/return": [32,[5]],
		"/o-logariasmos-mou/product": [33,[5]],
		"/p7U6keXmDN2RFpQ5EJdcE5YEpB3i8Q6UUjQkv23MUBRK5EV015TPctQ0rZ": [34],
		"/pricefoxoffer20": [~35],
		"/series/[id]": [36],
		"/signup": [37,[6]],
		"/signup/product-[productId=product]": [~38,[6,7]],
		"/signup/product-[productId=product]/[[coupon]]/email-[email]": [39,[6,7]],
		"/signup/product-[productId=product]/[[coupon]]/user-[userId]/payment-[paymentProvider]": [40,[6,7]],
		"/signup/product-[productId=product]/[[coupon]]/user-[userId]/payment-[paymentProvider]/return": [41,[6,7]],
		"/signup/subscription-[subscriptionId]": [42,[6]],
		"/support": [~43],
		"/syndesi": [44],
		"/terms/[page]": [~45],
		"/whatsupforyou": [46]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';