import type { ParamMatcher } from '@sveltejs/kit';
import {
	PUBLIC_ANNUAL_PRODUCTS,
	PUBLIC_MONTHLY_PRODUCTS,
	PUBLIC_DISCOUNT_PRODUCTS,
	PUBLIC_PARTNER_PRODUCTS
} from '$env/static/public';

export const match: ParamMatcher = (param: string) => {
	return [
		...PUBLIC_ANNUAL_PRODUCTS.split(','),
		...PUBLIC_MONTHLY_PRODUCTS.split(','),
		...PUBLIC_DISCOUNT_PRODUCTS.split(','),
		...PUBLIC_PARTNER_PRODUCTS.split(',')
	]
		.filter((id) => id !== '')
		.includes(param);
};
